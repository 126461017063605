import '../css/master-min.css';
import Photo1 from "../media/photos/1.jpg";
import Photo2 from "../media/photos/2.jpg";
import Photo3 from "../media/photos/3.jpg";
import Photo4 from "../media/photos/4.jpg";
import Photo5 from "../media/photos/5.jpg";
import Photo6 from "../media/photos/6.jpg";

function Photos() {
  return (
    <div className="photos">
      <div className="photos-section">
        <p className="section">Photos</p>
      </div>
      <div className="photo-boxes">
        <img src={Photo1} className="box" alt='Photos' />
        <img src={Photo2} className="box" alt='Photos' />
        <img src={Photo3} className="box" alt='Photos' />
        <img src={Photo4} className="box" alt='Photos' />
        <img src={Photo5} className="box" alt='Photos' />
        <img src={Photo6} className="box" alt='Photos' />
      </div>
    </div>
  )
}

export default Photos;