import "../css/master-min.css"

function BoxSNS(props) {
  return(
    <a href={props.link} className="box">
    <img src={props.imgSrc} alt={props.service} />
    <div className="overwiew">
      <p className="title">{props.service}</p>
      <p className="content">{props.children}</p>
    </div>  
  </a>

  )
}

export default BoxSNS;