import React from 'react';
import ReactDOM from 'react-dom/client';
import './css/master-min.css';
import Header from './layouts/header';
import Hero from './layouts/hero';
import Profire from './layouts/profire';
import Photos from './layouts/photos';
import SNS from './layouts/sns'
import Footer from './layouts/footer'
import reportWebVitals from './reportWebVitals';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <Header />
    <Hero />
    <Profire />
    <Photos />
    <SNS />
    <Footer />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
