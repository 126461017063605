import '../css/master-min.css';

function Box(props) {
  return(
    <div className='box'>
      <p className="m-i">{props.iconName}</p>
      <p className="box-text">{props.children}</p>
    </div>
  )
}

export default Box;
