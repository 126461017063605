import "../css/master-min.css";
import BoxSNS from "../components/snsbox";
import X from "../media/sns/X.svg";
import Instagram from "../media/sns/instagram.svg";
import YouTube from "../media/sns/youtube.svg";

function SNS() {
  return(
    <section className="sns">
      <p className="section">SNS</p>
      <div className="boxes">
        <BoxSNS link="https://x.com/MittsuTonari" imgSrc={X} service="X (Twitter)">
          日々ツイ廃を極めているアカウント。日常系のツイートが多いが、基本的にはYOASOBIが大好きすぎるだけである。
        </BoxSNS>
        <BoxSNS link="https://www.instagram.com/mittsutonari" imgSrc={Instagram} service="Instagram">
          YOASOBIが大好きな人におすすめのInstagram。最近特に投稿していない。（何を投稿したらいい？）
        </BoxSNS>
        <BoxSNS link="https://www.youtube.com/@MittsuTonari/" imgSrc={YouTube} service="YouTube">
          最も稼働していないアカウント。近々アカウントを移行しようとしているため動画はゼロ。なんかネタあったらDMまでようこそ。
        </BoxSNS>
      </div>
    </section>
  )
}

export default SNS;