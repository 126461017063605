import '../css/master-min.css';
import Box from "../components/box";

function Profire() {
  return (
    <section className="profire">
      <p className="name-mittsu">となりのみっつ</p>
      <p className="name-mittsu-en">MittsuTonari</p>
      <p className="section">Detail</p>
      <div className="detail">
        <Box iconName="cake">December 26</Box>
        <Box iconName="place">Tokyo, Japan</Box>
        <Box iconName="school">Student</Box>
        <Box iconName="visibility">Bad Eyesight</Box>
        <Box iconName="psychology">BAKA</Box>
        <Box iconName="photo_camera">Taking Photo</Box>
        <Box iconName="favorite">YOASOBI | Disney | Apple | etc...</Box>
        <Box iconName="code">JS | HTML | CSS etc...</Box>
        <Box iconName="waving_hand">Let's Wave to me!</Box>
        <Box iconName="co2">CO2</Box>
      </div>
    </section>
  )
}

export default Profire;