import '../css/master-min.css';
import LogoVideo from "../media/logo-video.apng";

function Hero() {
  return (
    <div className="hero">
      <div className="overlay" />
      <img src={LogoVideo} alt="logo video" id="logovideo" />
    </div>
  )
}

export default Hero;