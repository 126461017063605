import "../css/master-min.css"

function Footer() {
  return (
    <footer className="footer">
      <p>Copyright © {new Date().getFullYear()} MittsuTonari All rights reserved.</p>
    </footer>
  )
}

export default Footer;