import '../css/master-min.css';
import LogoTop from '../media/logo-top.svg';
import Logo from '../media/logo.png';

function Header() {
  return (
    <header className='header'>
      <img src={LogoTop} alt="logo" className="header-logo" />
      <img src={Logo} alt="logo" className="header-logo" />
    </header>
  );
}

export default Header;
